import type { FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { Root, ImageContainer, Text, SubText, Content, MainText } from './PresentationalComponents';

type BlankExperienceProps = {
	mainBanner: React.ReactNode;
	subBanner?: React.ReactNode;
	subBanner2?: React.ReactNode;
	callToAction?: JSX.Element;
	// Custom styles for blank experience image
	imageStyles?: object;
};

const blankExperienceImageStyle = css({
	float: 'none',
	maxHeight: '200px',
	paddingTop: token('space.250', '20px'),
	backgroundRepeat: 'no-repeat',
	margin: 'auto',
});

export const BlankExperience: FC<BlankExperienceProps> = (props: BlankExperienceProps) => {
	const { imageStyles, mainBanner, subBanner, subBanner2, callToAction } = props;

	return (
		<ErrorBoundary attribution={Attribution.BACKBONE}>
			<Root>
				<Content>
					<ImageContainer>
						<div
							data-testid="blank-experience-image"
							css={blankExperienceImageStyle}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							style={imageStyles}
						/>
					</ImageContainer>

					<Text data-testid="blank-experience-texts">
						<MainText>{mainBanner}</MainText>
						{subBanner ? <SubText>{subBanner}</SubText> : null}
						{subBanner2 ? <SubText>{subBanner2}</SubText> : null}
						{callToAction}
					</Text>
				</Content>
			</Root>
		</ErrorBoundary>
	);
};

BlankExperience.displayName = 'BlankExperience';
